const medicSearchAll = [{key: '-1',name: '全部',},{key: '1',name: '中草药',},{key: '2',name: '中成药',}];
const medicSearchHerbalMedicine = [{key: '-1',name: '全部'},{key: '1',name: '有毒'},{key: '3',name: '小毒'},{key: '2',name: '大毒'}];
const medicSearchPatentMedicine = [{key: '-1',name: '全部'},{key: '2',name: 'OTC甲'},{key: '3',name: 'OTC乙'},{key: '0',name: 'RX'}];

const medic = {
  state: {
    medicType: 0, // 0 首页 1 中草药 2 中成药
    medicTabSelect: medicSearchAll,
    collectMedicTabSelect: medicSearchAll
  },
  getters: {},
  mutations: {
    SET_MEDICTYPE (state, val) {
      state.medicType = val
      switch (val) {
        case 0:
          state.medicTabSelect = medicSearchAll;
          break;
        case 1:
          state.medicTabSelect = medicSearchHerbalMedicine;
          break;
        case 2:
          state.medicTabSelect = medicSearchPatentMedicine;
          break;
        default:
          break;
      }
    },
  },
  actions: {}
}

export default medic
