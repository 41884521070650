export default {
  state: {
    loading: true,
    mask: false
  },
  getters: {
  },
  mutations: {
    showLoad (state) {
      state.loading = true
    },
    hideLoad (state) {
      state.loading = false
    },
    showMask (state) {
      state.mask = true
    },
    hideMask (state) {
      state.mask = false
    }
  }
}
