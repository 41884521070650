<template>
  <div
    class="swiper-area"
    style="width: 100%;"
  >
    <swiper :options="swiperOption" ref="myswiper" v-if="swiperReset" style="height: auto;">
      <swiper-slide
        v-for="(item, index) in bannerList"
        :key="index"
      >
        <router-link
          :to="item.to"
          tag="div"
        >
          <img
            class="banner-item"
            :src="$store.getters.mobile ? item.mobileImg : item.img"
          />
          <p class="sinfo" v-if="item.info">{{item.info}}</p>
        </router-link>
      </swiper-slide>
      <div
        class="swiper-pagination"
        v-show="pagination"
        slot="pagination"
      ></div>
    </swiper>
    <div
      class="swiper-button-next swiper-next swiper-button"
      v-show="!$store.getters.mobile && bannerList.length > 1"
    ></div>
    <div
      class="swiper-button-prev swiper-prev swiper-button"
      v-show="!$store.getters.mobile && bannerList.length > 1"
    ></div>
    <div class="swiper-search" v-show="$route.path == '/home'">
      <div class="search-title">
        <!-- <img
          class="search-img"
          src="../assets/zlxcimgs/home_image-logoquan@2x.png"
        > -->
        <p class="search-circle"></p>
        <p class="search-text">关爱生命  关注安全</p>
      </div>
      <div class="search-con">
        <el-input class="search-input" v-model="drivingSchool" placeholder="请输入您要搜索的驾校" @change="tabSearchInput"></el-input>
        <div class="btn" @click="tabSearchInput">搜索</div>
      </div>
      <div class="downLoad">
        <div class="qrcodeflex"></div>
        <div class="qrCode">
          <img src="../assets/qrcode/xyaz.png" alt="">
        </div>
        <div class="qrCode">
          <img src="../assets/qrcode/xyios.png" alt="">
        </div>
        <div class="qrCode">
          <img src="../assets/qrcode/jxaz.png" alt="">
        </div>
        <div class="qrCode">
          <img src="../assets/qrcode/jxios.png" alt="">
        </div>
        <!-- <div style="color: #fff;">
          洛阳学车app 服务学员学车培训，主要功能有学员报名，缴费，预约科目培训，学时累计等功能
        </div> -->
        <div class="phoneType">
          <div class="typeItem">
            <div class="icon"><img src="../assets/zlxcimgs/ios.png" alt=""></div>
            <div class="txt">ios</div>
          </div>
          <div class="typeItem">
            <div class="icon"><img src="../assets/zlxcimgs/andriod.png" alt=""></div>
            <div class="txt">andriod</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapState} from 'vuex'
export default {
  props: {
    bannerList: {
      type: Array
    },
    pagination: {
      type: Boolean,
      default: true
    }
  },
  data () {
    return {
      swiperReset: true,
      swiperOption: {
        autoHeight: true,
        observer: true,//修改swiper自己或子元素时，自动初始化swiper
        observeParents: true,//修改swiper的父元素时，自动初始化swiper
        autoplay: {
          delay: 5000
        },
        speed: 500,
        loop: true,
        pagination: {
          el: '.swiper-pagination',
          clickable: true
          // bulletActiveClass: 'bullet-active'
        },
        navigation: {
          nextEl: '.swiper-next',
          prevEl: '.swiper-prev'
        }
      },
      // 搜索的驾校
      drivingSchool: ''
    }
  },
  computed: {
    ...mapState({
      mobile: state => state.user.mobile
    })
  },
  watch: {
    mobile() {
      // 修复轮播图切换图片loop下生成的solid不更新问题
      this.swiperReset = false;
      this.$nextTick(() => {
        this.swiperReset= true
      })
    }
  },
  methods: {
    tabSearchInput() {
      if (this.drivingSchool == '') return
      this.$router.push({
        name:'DrivingMap',
        query: {
          location: this.drivingSchool
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
  .swiper-area{
    width: 100vw;
    min-width: 1400px;
    // height: 571px;
    position: relative;
    top: 0;
    left: 0;
    /deep/ .swiper-container-autoheight, .swiper-container-autoheight .swiper-slide, .banner-item{
      height: auto;
    }
    .swiper-search{
      width: 40%;
      position: absolute;
      top: 18%;
      right: 10%;
      z-index: 999;
      .search-title{
        display: flex;
        align-items: center;
        width: 100%;
        margin-bottom: 18px;
        .search-img{
          width: 33%;
          margin-right: 33px;
        }
        .search-circle{
          width: 12px;
          height: 12px;
          border-radius: 50%;
          background: #F0AF12;
          margin-right: 18px;
        }
        .search-text{
          font-size: 24px;
          color: #FEFEFE;
        }
      }
      .search-con{
        display: flex;
        width: 100%;
        height: 68px;
        border-radius: 12px;
        overflow: hidden;
        background: #fff;
        .search-input{
          flex-grow: 1;
          height: 100%;
          line-height: 68px;
          font-size: 16px;
          color: #333333;
          /deep/ .el-input__inner{
            outline:none;  
            border:none;
          }
        }
        .btn{
          display: flex;
          align-items: center;
          justify-content: center;
          width: 20%;
          background: #FC9934;
          font-size: 24px;
          color: #FEFEFE;
          border-radius: 12px 0 0 12px;
          cursor: pointer;
        }
      }
    }
  }

  .downLoad {
    display: flex;
    align-items: center;
    padding-top: 30px;
  .qrCode {
    width: 160px;
    // min-width: 160px;
    // height: 160px;
    background: rgba(255,255,255,0.6);
    img {
      display: block;
      width: 100%;
      height: 100%;
    } 
  }

  .phoneType {
    padding: 10px 0 0 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    .typeItem {
      display: flex;
      align-items: center;
      // justify-content: center;
      width: 106px;
      height: 30px;
      border-radius: 6px;
      margin-bottom: 10px;
      background: #fff;
      .icon {
        width: 20px;
        height: 20px;
        margin: 0 10px;
        img {
          display: block;
          width: 100%;
          height: 100%;
        }
      }

      .txt {
        font-size: 16px;
        color: #333;
      }
    }
  }
}
</style>