<template>
  <div id="app" @click="$store.commit('CHANGE_OPENMENU', false)">
    <router-view/>
  </div>
</template>

<script>
export default {
  name: 'App',
      created() {
 
  },
}
</script>

<style>
#app {
  width: 100%!important;
  font-family: '微软雅黑';
  background: #ffffff;
}
html{
  overflow-y: scroll
}
@media only screen and (min-width: 1200px) {
  #app {
    min-height: 100vh;
  }
}
* {
  margin: 0;
  padding: 0;
}
[v-cloak] {
  display: none;
}
</style>
