import Vue from 'vue'
import Vuex from 'vuex'
import base from './modules/base'
import user from './modules/user'
import medic from './modules/medic'

Vue.use(Vuex)

const store = new Vuex.Store({
  modules: {
    base,
    user,
    medic
  },
  getters: {
    mobile: state => state.user.mobile,
    openMenu: state => state.user.openMenu,
    nativeInfo: state => state.user.nativeInfo,
    medicType: state => state.medic.medicType,
    medicTabSelect: state => state.medic.medicTabSelect,
    collectMedicTabSelect: state => state.medic.collectMedicTabSelect,
  }
})

export default store
