export default {
  install: function(Vue, option) {
    Vue.directive('noCopy', {
      inserted: (el) => {
        el.oncontextmenu = new Function("event.returnValue=false");
        el.onselectstart = new Function("event.returnValue=false");
        document.onkeydown=function(ev){
          var ev=window.event||ev;
            if((ev.ctrlKey) && (ev.keyCode==67)){
              ev.returnValue=false;
            }
        }
      }
    })
}
}