import { Message, MessageBox } from 'element-ui'
const baseUrl = 'http://192.168.0.122'

export default {
  install (Vue) {
    Vue.prototype.$baseUrl = baseUrl
    Vue.prototype.$errorToast = (msg) => {
      Message({
        message: msg,
        type: 'error',
        duration: 3000
      })
    }
    Vue.prototype.$warningToast = (msg) => {
      Message({
        message: msg,
        type: 'warning',
        duration: 3000
      })
    }
    Vue.prototype.$successToast = (msg) => {
      Message({
        message: msg,
        type: 'success',
        duration: 3000
      })
    }
    Vue.prototype.$confirmDelete = (msg, callback, title = '提示', type = 'warning') => {
      MessageBox.confirm(msg, title, {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: type
      }).then(() => {
        callback()
      }).catch(() => {
      })
    }
    Vue.prototype.$formatDate = (date) => {
      const dateTime = typeof date === 'object' ? date : new Date(date)
      const year = dateTime.getFullYear()
      const month = dateTime.getMonth() + 1 < 10 ? `0${dateTime.getMonth() + 1}` : dateTime.getMonth() + 1
      const day = dateTime.getDate() < 10 ? `0${dateTime.getDate()}` : dateTime.getDate()
      const hour = dateTime.getHours() < 10 ? `0${dateTime.getHours()}` : dateTime.getHours()
      const minute = dateTime.getMinutes() < 10 ? `0${dateTime.getMinutes()}` : dateTime.getMinutes()
      const second = dateTime.getSeconds() < 10 ? `0${dateTime.getSeconds()}` : dateTime.getSeconds()
      return `${year}-${month}-${day} ${hour}:${minute}:${second}`
    }
  }
}
