/*
 * @Author: enter
 * @Date: 2020-12-03 09:37:13
 * @LastEditTime: 2020-12-03 09:55:01
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \yxj-web\src\utils\filters.js
 */
import Vue from 'vue';

export function removeHtml (val) {
  if(val && typeof val == 'string') {
    return val.replace(/<[^>]*>/g, '').replace(/[&nbsp;]*/g, '');//去除文字的<...></...>标签
  }
}

Vue.filter('removeHtml', removeHtml);