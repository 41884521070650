import eventBus from '@/eventBus'
const user = {
  state: {
    mobile: true, // 菜单是否折叠
    openMenu: false,
    nativeInfo: {},
    userInfo: {},
    uid: 0,
    session: '',
    guid: '',
    version: ''
  },
  getters: {},
  mutations: {
    CHANGE_MOBILE (state, val) {
      state.mobile = val
    },
    CHANGE_OPENMENU (state, val) {
      state.openMenu = val
    },
    GET_NATIVEINFO (state, val) {
      state.nativeInfo = val
    },
    setUserInfo (state, info, flg) {
      state.userInfo = info
    },
    setBaseInfo (state, info) {
      state.uid = info.uid
      state.version = info.version
      state.session = info.session
      state.guid = info.guid
    }
  },
  actions: {}
}

export default user
