import Vue from 'vue'
import Router from 'vue-router'
import NProgress from 'nprogress'
import 'nprogress/nprogress.css'
NProgress.configure({ showSpinner: false })

const Wrapper = () => import('@/views/layout/Wrapper')
const Home = () => import('@/views/home/index')
const Login = () => import('@/views/login/index')

const Station = () => import('@/views/product/index')
const Download = () => import('@/views/media/questionBank/index')
const VideoLearning = () => import('@/views/media/videoLearning/index')
const Graduation = () => import('@/views/media/graduation/index')




const Practise = () => import('@/views/theoreticalStudy/practisePage/index')
const ChapterChoice = () => import('@/views/theoreticalStudy/chapterChoice/index')
const RealisticSimulation = () => import('@/views/theoreticalStudy/realisticSimulation/index')
const ExamPassed = () => import('@/views/theoreticalStudy/examPassed/index')
const ExamDnotpass = () => import('@/views/theoreticalStudy/examDnotpass/index')
const SpecialPractice = () => import('@/views/theoreticalStudy/specialPractice/index')
const About = () => import('@/views/about/index')
const Join = () => import('@/views/culture/index')
const Notice = () => import('@/views/noticeBoard/index')

const Recruitment = () => import('@/views/recruitment/index')
const DriveDetail = () => import('@/views/driveDetail/index')
const Instructor = () => import('@/views/instructor/index')
const Contact = () => import('@/views/contact/index')
const HomeContent = () => import('@/views/herbalMedicine/layout/content.vue')
const Details = () => import('@/views/details/index')
const DownloadPage = () => import('@/views/downloadPage/index')
const DrivingMap = () => import('@/views/drivingMap/index')
const HospitalMap = () => import('@/views/hospitalMap/index')
const DriveKnowledge = () => import('@/views/driveKnowledge/index')

Vue.use(Router)

const router = new Router({
  mode: 'hash',
  routes: [
    {
      path: '/',
      redirect: '/home'
    },
    {
      path: '/',
      name: 'Wrapper',
      redirect: '/home',
      component: Wrapper,
      children: [{
        path: 'home',
        name: 'Home',
        component: Home,
        meta: { title: '首页', keywords: '洛阳学车', description: '首页' }
      }, 
      {
        path: 'product',
        name: 'station',
        component: Station,
        meta: { title: '曝光台', keywords: '', description: '' }
      }, 

      {
        path: 'login',
        name: 'login',
        component: Login,
        meta: { title: '登录', keywords: '', description: '' }
      }, 
      
      {
        path: 'media',
        name: 'Download',
        redirect: '/media/questionBank',
        component: HomeContent,
        children: [
          
          
          {
            path: 'graduation',
            name: 'Graduation',
            component: Graduation,
            meta: { title: '结业鉴定', keywords: '', description: '' },
          },
          {
            path: 'videoLearning',
            name: 'VideoLearning',
            component: VideoLearning,
            meta: { title: '视频学习', keywords: '', description: '' },
          },
          {
            path: 'questionBank',
            name: 'questionBank',
            component: Download,
            meta: { title: '题库学习', keywords: '', description: '' },
          },
          {
            path: 'practise',
            name: 'Practise',
            component: Practise,
            meta: { title: '题库学习', keywords: '', description: '' },
          },
          {
            path: 'chapterChoice',
            name: 'ChapterChoice',
            component: ChapterChoice,
            meta: { title: '章节练习', keywords: '', description: '' },
          },
          {
            path: 'specialPractice',
            name: 'SpecialPractice',
            component: SpecialPractice,
            meta: { title: '专项练习', keywords: '', description: '' },
          },
          {
            path: 'realisticSimulation',
            name: 'RealisticSimulation',
            component: RealisticSimulation,
            meta: { title: '全真模拟', keywords: '', description: '' },
          },
          {
            path: 'examPassed',
            name: 'ExamPassed',
            component: ExamPassed,
            meta: { title: '全真模拟', keywords: '', description: '' },
          },
          {
            path: 'examDnotpass',
            name: 'ExamDnotpass',
            component: ExamDnotpass,
            meta: { title: '全真模拟', keywords: '', description: '' },
          }
        ]
      }, {
        path: 'about',
        name: 'About',
        component: About,
        meta: { title: '行业动态', keywords: '洛阳学车', description: '行业动态' }
      }, 
      {
        path: 'culture',
        name: 'Join',
        component: Join,
        meta: { title: '政策法规', keywords: '' }
      },  
      {
        path: 'notice',
        name: 'Notice',
        component: Notice,
        meta: { title: '公示栏', keywords: '' }
      }, 
      
      
      
      {
        path: 'recruitment',
        name: 'Recruitment',
        redirect: '/recruitment/driveschool',
        component: HomeContent,
        children: [
          {
            path: 'driveschool',
            name: 'Driveschool',
            component: Recruitment,
            meta: { title: '驾校风采', keywords: '', description: '' },
          },
          {
            path: 'instructor',
            name: 'Instructor',
            component: Instructor,
            meta: { title: '教练风采', keywords: '', description: '' },
          },
          {
            path: 'knowledge',
            name: 'DriveKnowledge',
            component: DriveKnowledge,
            meta: { title: '学驾须知', keywords: '', description: '' },
          },
          {
            path: 'drivedetail',
            name: 'Drivedetail',
            component: DriveDetail,
            meta: { title: '驾校详情', keywords: '', description: '' },
          }
        ]
      }, {
        path: 'contact',
        name: 'Contact',
        component: Contact,
        meta: { title: '投诉建议', keywords: '', description: '' }
      }, {
        path: 'details',
        name: 'Details',
        component: Details,
        meta: { title: '详情', keywords: '文章详情', description: '' }
      }, {
        path: 'download',
        name: 'DownloadPage',
        component: DownloadPage,
        meta: { title: '下载页', keywords: '下载页', description: '' }
      }, {
        path: 'map',
        name: 'DrivingMap',
        component: DrivingMap,
        meta: { title: '地图', keywords: '地图', description: '' }
      },{
        path: 'hmap',
        name: 'HospitalMap',
        component: HospitalMap,
        meta: { title: '地图', keywords: '体检', description: '' }
      }]
    }
  ]
})

router.beforeEach((to, from, next) => {
  Vue.nextTick(function(){
    document.title = to.meta.title ? `${to.meta.title} - 洛阳市驾驶员培训公共服务平台` : '洛阳市驾驶员培训公共服务平台'
    document.querySelector('meta[name="keywords"]').setAttribute('content', to.meta.keywords ? `${to.meta.keywords}` : '洛阳市驾驶员培训公共服务平台')
    document.querySelector('meta[name="description"]').setAttribute('content', to.meta.description ? `${to.meta.description}` : '洛阳市驾驶员培训公共服务平台')
  })
  next()
})

// router.afterEach((to, from) => {
  // NProgress.done()
// })

export default router
